import "./App.css";
import Parent2023 from "./components/Parent2023";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { theme2023 } from "./style/theme/theme2023";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mmb: 515,
      sm: 768,
      cd: 850,
      md: 981,
      lg: 1280,
      xl: 1536,
      xxl: 2000,
    },
  },
});

window.addEventListener("DOMContentLoaded", function () {
  setTimeout(() => {
    let hash = window.location.href;
    console.log(hash, "location Hash");
    let hashText = hash.split("#")[1];
    console.log(hashText, "location Hash Text");
    if (hashText !== undefined) {
      let element = document.getElementById(hashText);
      console.log(element, "location Hash element");
      element.scrollIntoView();
    }
  }, 500);
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Parent2023 theme={theme2023} />
      </ThemeProvider>
    </div>
  );
}

export default App;
